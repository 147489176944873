import React, { memo } from 'react';
import './Button.css';

const Button = ({ children, variant = 'contained', className = '', ...rest }) => {
  return (
    <button className={`button button--${variant} ${className}`} {...rest}>
      {children}
    </button>
  );
};

export default memo(Button);
