import React, { memo, useEffect } from 'react';
import './LanguageSelector.css';
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const path = window.location.pathname;
  const currentLanguage = path.split('/')[1] || 'en';

  useEffect(() => {
    if (['en', 'ua'].includes(currentLanguage)) {
      i18n.changeLanguage(currentLanguage);
    }
  }, []);


  const setLanguage = (newLang) => {
    if (newLang !== currentLanguage) {
      i18n.changeLanguage(newLang);
      window.history.pushState({}, '', '/' + newLang);
    }
  }

  return (
    <div className='language-selector'>
      <div className='language-option'>
        <img
          className='language-option__flag'
          src={`/img/${currentLanguage}.webp`}
          alt={t(`Header.language.${currentLanguage}.full`)}
        />
        <span>{t(`Header.language.${currentLanguage}.short`)}</span>
      </div>
      <div className='language-selector__options'>
        <div className='language-option' onClick={() => setLanguage('ua')}>
          <img className='language-option__flag' src="/img/ua.webp" alt="Ukrainian" />
          <span>{t('Header.language.ua.full')}</span>
        </div>
        <div className='language-option' onClick={() => setLanguage('en')}>
          <img className='language-option__flag' src="/img/en.webp" alt="English" />
          <span>{t('Header.language.en.full')}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(LanguageSelector);
