// src/components/Message.tsx
import React, {memo} from "react";
import {MessageDto} from "../../models/MessageDto";
import './Message.css';

interface MessageProps {
  message: MessageDto;
  loading: boolean;
  converter: any;
}

const Message: React.FC<MessageProps> = ({message, loading = false, converter}) => {
  return (
    <div className={`message ${message.isUser ? 'message--user' : 'message--assistant'}`}>
      {!message.isUser && (
        <div className='message__avatar-container'>
          <img src='/img/avatar.png' alt='Reservble assistant' className='message__avatar' />
        </div>
      )}
      <div className='message__content'>
        {!loading &&
          <div className='markdown-styles' dangerouslySetInnerHTML={{__html: converter.makeHtml(message.content)}} />
        }
        {loading && <div className='message__loading' />}
      </div>
      {message.isUser && <img src='/img/user.svg' alt='Your message' />}
    </div>
  );
};

export default memo(Message);
