import React, { memo } from "react";
import './Header.css';
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

const Header = () => {
  const { t } = useTranslation();

  const goToOnboarding = () => {
    window.open('https://restaurant.reservble.com/onboarding-form', '_blank', 'noopener,noreferrer');
  }

  return (
    <div className='header'>
      <div className='header__container'>
        <div className='header__links'>
          <a
            className='header__link'
            target='_blank'
            rel='noopener noreferrer'
            href='https://restaurant.reservble.com/'
          >
            {t('Header.aboutUs')}
          </a>
          <a
            className='header__link'
            target='_blank'
            rel='noopener noreferrer'
            href='https://reservble.com/search'
          >
            {t('Header.aiSearch')}
          </a>
        </div>
        <div className='header__logo-container'>
          <a target='_blank' rel='noopener noreferrer' href='https://restaurant.reservble.com'>
            <img alt='Reservble' src='/img/logo.svg' />
          </a>
        </div>
        <div className='header__actions'>
          <LanguageSelector />
          <Button onClick={goToOnboarding} className='header__try'>
            <span>{t('Header.try')}</span>
            <img src='/img/arrow-right.svg' alt='Try' />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
