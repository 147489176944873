// src/components/Chat.tsx
import React, {memo, useEffect, useState} from "react";
import Message from "../Message/Message";
import OpenAI from "openai";
import {MessageDto} from "../../models/MessageDto";
import './Chat.css';
import Button from "../Button/Button";
import {useTranslation} from "react-i18next";
import showdown from 'showdown';
import {use100vh} from "react-div-100vh";

const converter = new showdown.Converter();

const Chat: React.FC = () => {
  const {t} = useTranslation();
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
  const [input, setInput] = useState<string>("");
  const [thread, setThread] = useState<any>(null);
  const [openai, setOpenai] = useState<any>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const fullHeight = use100vh();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let chatHeight = fullHeight * 0.65;

  if (screenWidth < 480) {
    chatHeight = fullHeight - 170;
  } else if (screenWidth < 600) {
    chatHeight = fullHeight - 200;
  }

  useEffect(() => {
    initChatBot();
  }, []);

  const logMessage = (message, isUser) => {
    try {
      const body = {
        message,
        isUser,
        threadId: thread?.id,
      }
      fetch("https://resto.reservble.com/api/v1/internal/ailogging", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        }
      });
    } catch (e) {
      // ignore exception, continue flow
    }
  }

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: 'sk-proj-znsRdzUydRmQD9zWHoJuT3BlbkFJU6yhBY7L76OfrNimOXqC',
      dangerouslyAllowBrowser: true,
    });

    // Create a thread
    const thread = await openai.beta.threads.create();

    setOpenai(openai);
    setThread(thread);
  };

  const createNewMessage = (content: string, isUser: boolean) => {
    return new MessageDto(isUser, content);
  };

  const handleSendMessage = async () => {
    if (!input) {
      return;
    }

    logMessage(input, true);
    messages.push(createNewMessage(input, true));
    setMessages([...messages]);
    setInput("");

    // Send a message to the thread
    await openai.beta.threads.messages.create(thread.id, {
      role: "user",
      content: input,
    });

    // Run the assistant
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: 'asst_g2GeIjSOfAGFPiqNpc2JqfPV',
    });

    // Create a response
    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    // Wait for the response to be ready
    while (response.status === "in_progress" || response.status === "queued") {
      setIsWaiting(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    setIsWaiting(false);

    // Get the messages for the thread
    const messageList = await openai.beta.threads.messages.list(thread.id);

    // Find the last message for the current run
    const lastMessage = messageList.data
      .filter((message: any) => message.run_id === run.id && message.role === "assistant")
      .pop();

    // Print the last message coming from the assistant
    if (lastMessage) {
      setMessages([...messages, createNewMessage(lastMessage.content[0]["text"].value, false)]);
      logMessage(lastMessage.content[0]["text"].value, false);
    }
  };

  // detect enter key and send message
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <div className="chat-container" style={{ height: chatHeight }}>
      <div className='chat'>
        <div className='chat__header'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.reservble.com/places/100-rokiv'
            className='chat__rest-logo-container'
          >
            <img src='/img/rest_logo.png' alt='100 rokiv tomu vpered' className='chat__rest-logo' />
          </a>
          <div>
            <div className='chat__subtitle1'>{t('Chat.subtitle1')}</div>
            <div className='chat__subtitle2'>{t('Chat.subtitle2')}</div>
            <div className='chat__subtitle3'>{t('Chat.subtitle3')}</div>
          </div>
        </div>
        <div className='chat__messages'>
          {messages.map((message, index) =>
            <Message key={index}
              message={message}
              loading={false}
              converter={converter} />
          )}
          {isWaiting && <Message message={{isUser: false, content: ""}} loading converter={converter} />}
        </div>
        <div className='chat__actions'>
          <input
            className='chat__input'
            placeholder={t('Chat.placeholder')}
            disabled={isWaiting}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <Button variant='outlined' className='chat__send' onClick={handleSendMessage} disabled={isWaiting}>
            <span className='chat__send-text'>{t('Chat.send')}</span>
            <img src='/img/send.svg' alt='Send' />
          </Button>
        </div>
      </div>
      <img className='leaf leaf-1' src='/img/bg/leaf1.png' alt='Leaf' />
      <img className='leaf leaf-2' src='/img/bg/leaf2.png' alt='Leaf' />
      <img className='leaf leaf-3' src='/img/bg/leaf3.png' alt='Leaf' />
      <img className='leaf leaf-4' src='/img/bg/leaf4.png' alt='Leaf' />
      <img className='leaf leaf-5' src='/img/bg/leaf5.png' alt='Leaf' />
    </div>
  );
};

export default memo(Chat);
