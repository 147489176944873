// src/App.tsx
import React from 'react';
import Chat from './components/Chat/Chat';
import Header from "./components/Header/Header";
import './App.css';
import Footer from "./components/Footer/Footer";
import Photos from "./components/Photos/Photos";
import {use100vh} from "react-div-100vh";

const App: React.FC = () => {
  const fullHeight = use100vh();

  return (
    <div className='app' style={{minHeight: fullHeight}}>
      <Header />
      <main style={{paddingLeft: 16, paddingRight: 16, overflowX: 'hidden'}}>
        <Chat />
      </main>
      <Footer />
      <Photos />
    </div>
  );
};

export default App;
