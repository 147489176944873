import React, { memo } from 'react';
import './Footer.css';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className='footer'>
      <div className='footer__text'>{t('Footer.text')}</div>
    </div>
  );
};

export default memo(Footer);
