import React, { memo } from 'react';
import './Photos.css';

const Photos = () => {
  return (
    <div className='photos'>
      <img src='/img/klopotenko.png' className='photo photo--klopotenko' alt='Ievgen Klopotenko' />
      <img src='/img/inna.png' className='photo photo--inna' alt='Inna Popereshniuk' />
    </div>
  );
};

export default memo(Photos);
